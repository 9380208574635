<template>
<div class="flex justify-center">
    <div class="footer w-full mw-1920">
        <div class="footer-desktop">
            <div class="footer-item w-20">
                <div class="logo">
                    <img :src="'https://avior.prestigeautofl.com'+footer.files[`logo_${$i18n.locale}`]" alt="">
                </div>
                <div class="sotsials">
                    <a :href="footer.data.facebook">
                        <div>
                            <img src="@/assets/icons/facebook.svg" alt="">
                        </div>
                    </a>
                    <a :href="footer.data.twitter">
                        <div>
                            <img src="@/assets/icons/twitter.svg" alt="">
                        </div>
                    </a>
                    <a :href="footer.data.instagram">
                        <div>
                            <img src="@/assets/icons/instagram.svg" alt="">
                        </div>
                    </a>
                </div>
            </div>
            <div class="footer-linkes">
                <div class="footer-item w-33">
                    <div class="footer-link">
                        <div class="footer-link-block">
                            <router-link tag="a" to="/" active-class="active-footer" exact>
                                <p> {{ home.data.elements[`title_${$i18n.locale}`] }} </p>
                                <div class="footer-link-line">
                                    <div></div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="footer-item w-33" v-if="credit.id">
                    <div class="footer-link">
                        <div class="footer-link-block">
                            <router-link tag="a" to="/credit" active-class="active-footer" exact>
                                <p> {{credit.data[`name_${$i18n.locale}`]}} </p>
                                <div class="footer-link-line">
                                    <div></div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="footer-item w-33" v-if="leasing.id">
                    <div class="footer-link">
                        <div class="footer-link-block">
                            <router-link tag="a" to="/leasing" active-class="active-footer" exact>
                                <p> {{leasing.data[`name_${$i18n.locale}`]}} </p>
                                <div class="footer-link-line">
                                    <div></div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="footer-item w-33" v-if="stock.id">
                    <div class="footer-link">
                        <div class="footer-link-block">
                            <router-link tag="a" to="/stocks" active-class="active-footer" exact>
                                <p> {{stock.data[`name_${$i18n.locale}`]}} </p>
                                <div class="footer-link-line">
                                    <div></div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="footer-item w-33" v-if="faq.id">
                    <div class="footer-link">
                        <div class="footer-link-block">
                            <router-link tag="a" to="/faq" active-class="active-footer" exact>
                                <p> {{ faq.data[`name_${$i18n.locale}`] }} </p>
                                <div class="footer-link-line">
                                    <div></div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="footer-item w-33" v-if="company.id">
                    <div class="footer-link">
                        <div class="footer-link-block">
                            <router-link tag="a" to="/procompany" active-class="active-footer" exact>
                                <p> {{company.data[`name_${$i18n.locale}`]}} </p>
                                <div class="footer-link-line">
                                    <div></div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="footer-item w-33" v-if="contact.id">
                    <div class="footer-link">
                        <div class="footer-link-block">
                            <router-link tag="a" to="/contact" active-class="active-footer" exact>
                                <p> {{contact.data[`name_${$i18n.locale}`]}} </p>
                                <div class="footer-link-line">
                                    <div></div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="footer-item w-33" v-if="advantage.id">
                    <div class="footer-link">
                        <div class="footer-link-block">
                            <router-link tag="a" to="/advantages" active-class="active-footer" exact>
                                <p> {{advantage.data[`name_${$i18n.locale}`]}} </p>
                                <div class="footer-link-line">
                                    <div></div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="footer-item w-33" v-if="me.id">
                    <div class="footer-link">
                        <div class="footer-link-block">
                            <router-link tag="a" to="/me" active-class="active-footer" exact>
                                <p> {{navPage.data[`accaunt_${$i18n.locale}`]}} </p>
                                <div class="footer-link-line">
                                    <div></div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="footer-item w-33" v-if="me.id">
                    <div class="footer-link">
                        <div class="footer-link-block">
                            <router-link tag="a" to="/me/credit" active-class="active-footer" exact>
                                <p> {{navPage.data[`credit_${$i18n.locale}`]}} </p>
                                <div class="footer-link-line">
                                    <div></div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="footer-item w-33" v-if="me.id">
                    <div class="footer-link">
                        <div class="footer-link-block">
                            <router-link tag="a" to="/me/message" active-class="active-footer" exact>
                                <p> {{navPage.data[`message_${$i18n.locale}`]}} </p>
                                <div class="footer-link-line">
                                    <div></div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="footer-item w-33" v-if="me.id">
                    <div class="footer-link">
                        <div class="footer-link-block">
                            <router-link tag="a" to="/me/history" active-class="active-footer" exact>
                                <p> {{navPage.data[`history_${$i18n.locale}`]}}</p>
                                <div class="footer-link-line">
                                    <div></div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="footer-item w-33" v-for="(item, i) in page" :key="i">
                    <div class="footer-link">
                        <div class="footer-link-block">
                            <router-link tag="a" :to="`/page/${item.url}`" active-class="active-footer" exact>
                                <p> {{item.url_name_ua}} </p>
                                <div class="footer-link-line">
                                    <div></div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>

            </div>

            <div class="footer-item w-20">
                <button @click="modal=true"   class="footer-button"> {{footer.data[`button_${$i18n.locale}`] }} </button>
                <p class="simple-p"> {{footer.data[`adress_${$i18n.locale}`]}} </p>
                <p class="bold-p">{{footer.data.phone}}</p>
            </div>
            <div class="footer-bottom">
                <div>
                    <p>{{footer.data[`text_left_${$i18n.locale}`]}}</p>
                </div>
                <div class="w-20">
                    <p>{{footer.data[`text_right_${$i18n.locale}`]}} <a :href="footer.data.text_right_url">{{footer.data[`text_right_name_${$i18n.locale}`]}}</a></p>
                </div>
            </div>

        </div>

        <!-- Footer Phone -->
        <div class="footer-phone">
            <div class="footer-phone-linkes">
                <div class="footer-item  footer-phone-linkes-item">
                    <div class="footer-link">
                        <div class="footer-link-block">
                            <router-link tag="a" to="/" active-class="active-footer" exact>
                                <p> {{ home.data.elements[`title_${$i18n.locale}`] }} </p>
                                <div class="footer-link-line">
                                    <div></div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="footer-item footer-phone-linkes-item" v-if="credit.id">
                    <div class="footer-link" >
                        <div class="footer-link-block">
                        <router-link tag="a" to="/credit" active-class="active-footer" exact>
                            <p> {{credit.data[`name_${$i18n.locale}`]}} </p>
                            <div class="footer-link-line">
                                <div></div>
                            </div>
                        </router-link>
                        </div>
                    </div>
                </div>
                <div class="footer-item footer-phone-linkes-item" v-if="leasing.id">
                    <div class="footer-link" >
                        <div class="footer-link-block">
                        <router-link tag="a" to="/leasing" active-class="active-footer" exact>
                            <p> {{leasing.data[`name_${$i18n.locale}`]}} </p>
                            <div class="footer-link-line">
                                <div></div>
                            </div>
                        </router-link>
                        </div>
                    </div>
                </div>
                <div class="footer-item footer-phone-linkes-item" v-if="stock.id">
                    <div class="footer-link" >
                        <div class="footer-link-block">
                        <router-link tag="a" to="/stocks" active-class="active-footer" exact>
                            <p> {{stock.data[`name_${$i18n.locale}`]}} </p>
                            <div class="footer-link-line">
                                <div></div>
                            </div>
                        </router-link>
                        </div>
                    </div>
                </div>
                <div class="footer-item footer-phone-linkes-item" v-if="faq.id">
                    <div class="footer-link" >
                        <div class="footer-link-block">
                        <router-link tag="a" to="/faq" active-class="active-footer" exact>
                            <p> {{ faq.data[`name_${$i18n.locale}`] }}  </p>
                            <div class="footer-link-line">
                                <div></div>
                            </div>
                        </router-link>
                        </div>
                    </div>
                </div>
                <div class="footer-item footer-phone-linkes-item" v-if="company.id">
                    <div class="footer-link" >
                        <div class="footer-link-block">
                        <router-link tag="a" to="/procompany" active-class="active-footer" exact>
                            <p> {{company.data[`name_${$i18n.locale}`]}} </p>
                            <div class="footer-link-line">
                                <div></div>
                            </div>
                        </router-link>
                        </div>
                    </div>
                </div>
                <div class="footer-item footer-phone-linkes-item" v-if="contact.id">
                    <div class="footer-link" >
                        <div class="footer-link-block">
                        <router-link tag="a" to="/contact" active-class="active-footer" exact>
                            <p> {{contact.data[`name_${$i18n.locale}`]}} </p>
                            <div class="footer-link-line">
                                <div></div>
                            </div>
                        </router-link>
                        </div>
                    </div>
                </div>
                <div class="footer-item footer-phone-linkes-item" v-if="advantage.id">
                    <div class="footer-link" >
                        <div class="footer-link-block">
                        <router-link tag="a" to="/advantages" active-class="active-footer" exact>
                            <p> {{advantage.data[`name_${$i18n.locale}`]}} </p>
                            <div class="footer-link-line">
                                <div></div>
                            </div>
                        </router-link>
                        </div>
                    </div>
                </div>
                <div class="footer-item footer-phone-linkes-item" v-if="me.id">
                    <div class="footer-link" >
                        <div class="footer-link-block">
                        <router-link tag="a" to="/me" active-class="active-footer" exact>
                            <p> {{navPage.data[`accaunt_${$i18n.locale}`]}} </p>
                            <div class="footer-link-line">
                                <div></div>
                            </div>
                        </router-link>
                        </div>
                    </div>
                </div>
                <div class="footer-item footer-phone-linkes-item" v-if="me.id">
                    <div class="footer-link" >
                        <div class="footer-link-block">
                        <router-link tag="a" to="/me/credit" active-class="active-footer" exact>
                            <p> {{navPage.data[`credit_${$i18n.locale}`]}} </p>
                            <div class="footer-link-line">
                                <div></div>
                            </div>
                        </router-link>
                        </div>
                    </div>
                </div>
                <div class="footer-item footer-phone-linkes-item" v-if="me.id">
                    <div class="footer-link" >
                        <div class="footer-link-block">
                        <router-link tag="a" to="/me/message" active-class="active-footer" exact>
                            <p> {{navPage.data[`message_${$i18n.locale}`]}} </p>
                            <div class="footer-link-line">
                                <div></div>
                            </div>
                        </router-link>
                        </div>
                    </div>
                </div>
                <div class="footer-item footer-phone-linkes-item" v-if="me.id">
                    <div class="footer-link" >
                        <div class="footer-link-block">
                        <router-link tag="a" to="/me/history" active-class="active-footer" exact>
                            <p> {{navPage.data[`history_${$i18n.locale}`]}}</p>
                            <div class="footer-link-line">
                                <div></div>
                            </div>
                        </router-link>
                        </div>
                    </div>
                </div>






                <div class="footer-item footer-phone-linkes-item" v-for="(item, i) in page" :key="i">
                    <div class="footer-link">
                        <router-link tag="a" :to="`/page/${item.url}`">
                            <p> {{item.url_name_ua}}</p>
                        </router-link>
                    </div>
                </div>

            </div>
            <div class="footer-item ww-70">
                <button @click="modal=true"  class="footer-button">{{footer.data[`button_${$i18n.locale}`] }} </button>
                <p class="simple-p"> {{footer.data[`adress_${$i18n.locale}`]}} </p>
                <p class="bold-p">{{footer.data.phone}}</p>
            </div>

            <div class="ww-30">
                <div class="sotsial-phone">
                    <a :href="footer.data.facebook">
                        <div>
                            <img src="@/assets/icons/facebook.svg" alt="">
                        </div>
                    </a>
                </div>
                <div class="sotsial-phone">
                    <a :href="footer.data.twitter">
                        <div>
                            <img src="@/assets/icons/twitter.svg" alt="">
                        </div>
                    </a>
                </div>
                <div class="sotsial-phone">
                    <a :href="footer.data.instagram">
                        <div>
                            <img src="@/assets/icons/instagram.svg" alt="">
                        </div>
                    </a>
                </div>
            </div>
            <div class="footer-about-company">
                <p>{{footer.data[`text_left_${$i18n.locale}`]}} </p>
                <p>{{footer.data[`text_right_${$i18n.locale}`]}} <a :href="footer.data.text_right_url">{{footer.data[`text_right_name_${$i18n.locale}`]}}</a></p>
            </div>
        </div>
    </div>

    <div class="modal" :class="modal?'active':''">
        <div @click="modal=false" class="modal-cover"></div>
        <div class="contact-card" v-loading="smsLoading">
            <el-form ref="smsForm" :model="sms" :rules="smsRules">
                <h1> {{$t('coming_soon')}} </h1>
                <el-form-item prop="name">
                    <el-input :placeholder="$t('enter_mail')" class="contact-card-input" v-model="sms.name"></el-input>
                </el-form-item>
                <el-form-item prop="text">
                    <el-input type="textarea" :rows="5" class="contact-card-text-area" :placeholder="$t('enter_text')" v-model="sms.text"> </el-input>
                </el-form-item>
                <div class="flex justify-center">
                    <button class="button" type="button" @click="SendSms"> {{$t('contact_send')}} </button>
                </div>
            </el-form>
        </div>
    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            page: [],
            show: false,
            sms: {
                name: '',
                text: ''
            },
            modal: false,
            smsLoading: false,
            smsRules: {
                name: [{
                     required: true,
                     type:'email',
                     message: 'Введіть вашу електронну пошт!',
                     trigger: 'change'
                }],
                text: [{
                    required: true,
                    message: this.$t('input_text_please'),
                    trigger: 'change'
                }],
            }
        }
    },
    computed: {
        pages() {
            return this.$store.state.Pages
        },
        home() {
            return this.$store.state.Home
        },
        stock() {
            return this.$store.state.Stock
        },
        credit() {
            return this.$store.state.Credit
        },
        contact() {
            return this.$store.state.Contact
        },
        faq() {
            return this.$store.state.FAQ
        },
        company() {
            return this.$store.state.Company
        },
        leasing() {
            return this.$store.state.Leasing
        },
        advantage() {
            return this.$store.state.Advantages

        },
        footer() {
            return this.$store.state.Footer
        },
        me() {
            return this.$store.state.me
        },
        navPage() {
            return this.$store.state.Nav
        },

    },
    watch: {
        pages() {
            this.page = this.pages.filter(a => {
                return a.footer == true
            })

        }
    },
    methods:{
        SendSms() {
             this.$refs.smsForm.validate((valid) => {
                 if (valid) {
                     this.$notify({
                         title: 'Успех',
                         message: 'Ваш запрос был успешно отправлен!',
                         type: 'success'
                     });
                     this.smsLoading = false
                     this.$refs.smsForm.resetFields();
                     this.modal=false
                 } else {
                     return false;
                 }
             });
         }
    },
    mounted() {

        this.page = this.pages.filter(a => {
            return a.footer == true
        })
    }
}
</script>

<style lang="scss">
.footer {
    width: 100%;
    background: var(--white-bg);

    .w-20 {
        width: 20%;
        padding: 0px 10px;
    }

    .logo {
        img {
            width: 150px;
        }
    }

    .sotsials {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 80px;

        a {
            margin-right: 30px;
        }
    }

    .footer-link {
        margin-bottom: 10px;

        .footer-link-block {
            width: fit-content;
            position: relative;
        }

        .footer-link-line {
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;

            div {
                width: 0%;
                transition: all 0.2s ease-in;
                height: 1px;
                background: var(--black);
            }
        }

        a {
            text-decoration: none;
            color: var(--other-black);
        }

        p {
            font-family: Stem-Regular;
            font-size: 0.875rem;
            line-height: 22px;
            display: block;
            padding-bottom: 1px;
        }

        .footer-link-block:hover {
            .footer-link-line {
                div {
                    width: 100%;
                }
            }
        }

        .active-footer {
            .footer-link-line {
                div {
                    width: 100%;
                }
            }
        }
    }

    .footer-button {
        width: 100%;
        border: none;
        background: #00B5EC;
        border-radius: 4px;
        padding: 15px 20px;
        font-family: Stem-Medium;
        font-size: 1rem;
        line-height: 1.125rem;
        color: var(--white-color);
        cursor: pointer;
        transition: all 0.3s ease-in;
        border: 1px solid #00B5EC;
    }

    .footer-button:hover {

        color: var(--color-blue);
        background: var(--white-bg);
    }

    .simple-p {
        font-family: Stem-Regular;
        font-size: 0.875rem;
        line-height: 1rem;
        display: flex;
        align-items: center;
        color: var(--other-black);
        margin-top: 20px;
    }

    .bold-p {
        font-family: Stem-Medium;
        font-size: 1rem;
        line-height: 1.125rem;
        display: flex;
        align-items: center;
        margin-top: 20px;
        color: var(--other-black);
    }

    .footer-bottom {
        width: 100%;
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            font-family: Stem-Regular;
            font-size: 0.75rem;
            line-height: 24px;
            font-feature-settings: 'tnum'on, 'lnum'on;
            color: var(--white-black);

            a {
                color: var(--white-black);

            }
        }

    }
}

.footer-desktop {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 50px 100px;
}

.footer-phone {
    display: none;
}

.footer-linkes {
    width: 60%;
    display: flex;
    flex-wrap: wrap;

    .w-33 {
        width: 33%;
    }
}

@media screen and(max-width:1100px) {
    .footer-desktop {
        padding: 50px 10px;

    }
}

@media screen and(max-width:950px) {
    .footer-linkes {
        min-width: 70% !important;
    }

    .footer {
        .w-20 {
            width: 30%;
            margin-top: 20px;
        }
    }

}

@media screen and(max-width:768px) {
    .footer-desktop {
        display: none;
    }

    .footer-phone {
        width: 100%;
        padding: 30px 20px;
        display: flex;
        flex-wrap: wrap;

        .footer-phone-linkes {
            display: flex;
            width: 100%;
            flex-wrap: wrap;

            .footer-phone-linkes-item:nth-child(even) {
                width: 50%;
                padding-left: 10px;
            }

            .footer-phone-linkes-item:nth-child(odd) {
                width: 50%;
                padding-right: 10px;
            }

        }

        .footer-right {
            width: 50%;
            padding-left: 10px;
        }

        .footer-left {
            width: 50%;
            padding-right: 10px;
        }

        .ww-70 {
            width: 70%;
            padding-right: 20px;
            margin-top: 60px;
        }

        .ww-30 {
            width: 30%;
            margin-top: 60px;

        }

        .sotsial-phone {
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 10px 0px 15px 0px;
        }

        .footer-about-company {
            padding-right: 20px;

            p {
                font-family: Stem-Regular;
                font-size: 0.75rem;
                line-height: 24px;
                display: flex;
                align-items: center;
                font-feature-settings: 'tnum'on, 'lnum'on;
                color: var(--white-black);
                margin-top: 10px;
            }
        }
    }

    .footer {
        .footer-button {
            padding: 10px 20px;
            font-size: 1rem;
            line-height: 1.125rem;
        }

        .simple-p {
            font-family: Stem-Regular;
            font-size: 0.875rem;
            line-height: 1rem;
            display: flex;
            align-items: center;
            color: var(--other-black);
            margin-top: 20px;
        }

        .bold-p {
            font-family: Stem-Medium;
            font-size: 1rem;
            line-height: 1.125rem;
            display: flex;
            align-items: center;
            margin-top: 20px;
        }

    }
}
</style>
