import Vue from 'vue'
import axios from 'axios';
import router from '../../router/index'

let base_url = 'https://avior.prestigeautofl.com/api';
axios.defaults.baseURL = base_url
axios.defaults.headers.common[ "Authorization" ] = `Bearer ${localStorage.token}`;
global.axios = axios  



axios.interceptors.response.use((response) => {
  return response
}, function (error) { 

  if (error.response.status === 401 ) {
    localStorage.role=null 
    if(router.currentRoute.meta.requiresAuth){
      router.push('/login');
    }  
    return Promise.reject(error);
  }
  if (error.response.status === 403 ) {
    router.push('/');
    return Promise.reject(error);
  }
  return Promise.reject(error);
});

Vue.prototype.axios = axios