import Vue from 'vue'
import VueRouter from 'vue-router'  
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home', 
    component: () => import('../views/Home.vue')
  },
  {
    path: '/page/:type',
    name: 'home-type', 
    component: () => import('../views/Page.vue')
  },
  {
    path: '/me',
    name: 'me',
    meta: { requiresAuth: true },
    component: () => import('../views/Cabinet/index.vue')
  },
  {
    path: '/me/credit',
    name: 'me-credit',
    meta: { requiresAuth: true },
    component: () => import('../views/Cabinet/index.vue')
  },
  {
    path: '/me/history',
    name: 'me-history',
    meta: { requiresAuth: true },
    component: () => import('../views/Cabinet/index.vue')
  },
  {
    path: '/me/message',
    name: 'me-message',
    meta: { requiresAuth: true },
    component: () => import('../views/Cabinet/index.vue')
  },
  {
    path: '/me/message/:id',
    name: 'show-message',
    component: () => import('../views/Cabinet/index.vue')
  },
  {
    path: '/stocks',
    name: 'stocks',
    component: () => import('../views/Stocks/index.vue')
  },
  {
    path: '/stocks/:id',
    name: 'stock',
    component: () => import('../views/Stocks/_id.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('../views/Faq.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/credit',
    name: 'credit',
    meta: { requiresAuth: false },
    component: () => import('../views/Credit.vue')
  },
  {
    path: '/advantages',
    name: 'advantages',
    component: () => import('../views/Advantages.vue')
  },
  {
    path: '/procompany',
    name: 'procompany',
    component: () => import('../views/ProCompany.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login/Login.vue')
  },
  {
    path: '/reset',
    name: 'reset',
    component: () => import('../views/Login/Reset.vue')
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import('../views/Login/Register.vue')
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: () => import('../views/Login/Confirm.vue')
  },
  {
    path: '/recovepassword',
    name: 'recovepassword',
    component: () => import('../views/Login/ResetPassword.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../views/Terms.vue')
  },
  {
    path: '/leasing',
    name: 'leasing',
    component: () => import('../views/Leasing/index.vue')
  },
  {
    path: '/leasing/technique',
    name: 'technique',
    component: () => import('../views/Leasing/TechniqueList.vue')
  },
  {
    path: '/leasing/technique/:id',
    name: 'technique-one',
    component: () => import('../views/Leasing/_id.vue')
  },
  {
    path: '/leasing/technique/get/:id',
    name: 'technique-get',
    component: () => import('../views/Leasing/GetLeasing.vue')
  },
  {
    path: '/404',
    name: 'bug',
    component: () => import('../views/404.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue')
  },

  // Admin
  {
    path: '/admin',
    name: 'admin-stocks',
    component: () => import('../views/Admin/Stocks/index.vue'),
  },
  {
    path: '/admin/login',
    name: 'admin-login',
    component: () => import('../views/Admin/Login/index.vue')
  },
  {
    path: '/admin/stocks/add',
    name: 'admin-stocks-add',
    component: () => import('../views/Admin/Stocks/add.vue')
  },
  {
    path: '/admin/stocks/edit/:id',
    name: 'admin-stocks-edit',
    component: () => import('../views/Admin/Stocks/edit.vue')
  },
  {
    path: '/admin/pages',
    name: 'admin-pages',
    component: () => import('../views/Admin/Pages/index.vue')
  },
  {
    path: '/admin/pages/add',
    name: 'admin-pages-add',
    component: () => import('../views/Admin/Pages/add.vue')
  },
  {
    path: '/admin/pages/edit/:id',
    name: 'admin-pages-edit',
    component: () => import('../views/Admin/Pages/edit.vue')
  },
  {
    path: '/admin/pages/:id/templates',
    name: 'admin-template-list',
    component: () => import('../views/Admin/Pages/TemplateList.vue')
  },
  {
    path: '/admin/pages/:id/template/add',
    name: 'admin-templates-add',
    component: () => import('../views/Admin/Pages/TemplateAdd.vue')
  },
  {
    path: '/admin/pages/:id/template/edit/:template_id',
    name: 'admin-template-edit',
    component: () => import('../views/Admin/Pages/TemplateEdit.vue')
  },
  {
    path: '/admin/statpages',
    name: 'StaticPages',
    component: () => import('../views/Admin/StaticPages/index.vue')
  },
  {
    path: '/admin/statpages/edit/home',
    name: 'homeEdit',
    component: () => import('../views/Admin/StaticPages/homeEdit.vue')
  },
  {
    path: '/admin/statpages/edit/stock',
    name: 'stockEdit',
    component: () => import('../views/Admin/StaticPages/stockEdit.vue')
  },
  {
    path: '/admin/statpages/edit/getcredit',
    name: 'getCreditEdit',
    component: () => import('../views/Admin/StaticPages/getCreditEdit.vue')
  },
  {
    path: '/admin/statpages/edit/contact',
    name: 'contactEdit',
    component: () => import('../views/Admin/StaticPages/contactEdit.vue')
  },
  {
    path: '/admin/statpages/edit/faq',
    name: 'faqEdit',
    component: () => import('../views/Admin/StaticPages/faqEdit.vue')
  },
  {
    path: '/admin/statpages/edit/leasing',
    name: 'aboutcompanyEdit',
    component: () => import('../views/Admin/StaticPages/leasingEdit.vue')
  },
  {
    path: '/admin/statpages/edit/advantages',
    name: 'advantagesEdit',
    component: () => import('../views/Admin/StaticPages/advantagesEdit.vue')
  },
  {
    path: '/admin/statpages/edit/procompany',
    name: 'procompanyEdit',
    component: () => import('../views/Admin/StaticPages/procompanyEdit.vue')
  },
  {
    path: '/admin/statpages/edit/calculator',
    name: 'calculatorEdit',
    component: () => import('../views/Admin/StaticPages/calculatorEdit.vue')
  },
  {
    path: '/admin/statpages/edit/footer',
    name: 'footerEdit',
    component: () => import('../views/Admin/StaticPages/footerEdit.vue')
  },
  {
    path: '/admin/statpages/edit/nav',
    name: 'navEdit',
    component: () => import('../views/Admin/StaticPages/navEdit.vue')
  },
  {
    path: '/admin/statpages/edit/me',
    name: 'meEdit',
    component: () => import('../views/Admin/StaticPages/meEdit.vue')
  },
  {
    path: '/admin/files',
    name: 'files',
    component: () => import('../views/Admin/Files/index.vue')
  },
  // FAQ
  {
    path: '/admin/faq',
    name: 'admin-faq',
    component: () => import('../views/Admin/Faq/index.vue')
  },
  {
    path: '/admin/faq/add',
    name: 'admin-faq-add',
    component: () => import('../views/Admin/Faq/add.vue')
  },
  {
    path: '/admin/faq/edit/:id',
    name: 'admin-faq-edit',
    component: () => import('../views/Admin/Faq/edit.vue')
  },
  // FAQ Categoys
  {
    path: '/admin/category-faq',
    name: 'category-faq',
    component: () => import('../views/Admin/Faq/FaqCategory.vue')
  },
  {
    path: '/admin/category-faq/add',
    name: 'category-faq-add',
    component: () => import('../views/Admin/Faq/FaqCategoryAdd.vue')
  },
  {
    path: '/admin/category-faq/edit/:id',
    name: 'category-faq-edit',
    component: () => import('../views/Admin/Faq/FaqCategoryEdit.vue')
  },

  {
    path: '/admin/products',
    name: 'admin-products',
    component: () => import('../views/Admin/Products/index.vue')
  },
  {
    path: '/admin/products/add',
    name: 'admin-products-add',
    component: () => import('../views/Admin/Products/add.vue')
  },
  {
    path: '/admin/products/edit/:id',
    name: 'admin-products-edit',
    component: () => import('../views/Admin/Products/edit.vue')
  },
  {
    path: '/admin/users',
    name: 'admin-users',
    component: () => import('../views/Admin/Users/index.vue')
  },
  {
    path: '/admin/kredit',
    name: 'admin-kredit',
    component: () => import('../views/Admin/Kredit/index.vue')
  },
 
]

const router = new VueRouter({
  mode:'history',
  routes,
  scrollBehavior () {
    
    return { x: 0, y: 0, 
      // behavior: 'smooth'
     }
  }
})




router.beforeResolve((to, from, next) => {
  if (to.name) {
    router.app.$nprogress.start()
    router.app.$nprogress.set(10)
  }
  next()
})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  // router.app.$nprogress.done()



  setTimeout(() => router.app.$nprogress.done(), 500)
})
export default router
