<template>
<div id="app">
    <div class="user-view" v-if="name!='admin'" id="all">
        <Nav class="app-nav" />
        <router-view v-if="$route.name"></router-view>
        <div class="scleton-image-avior" v-else>
        </div>
        <Footer />
    </div>
    <div class="admin-view w-full minw-100" v-else>
        <AdminNav v-if="$route.path!='/admin/login'" />
        <div class="flex w-full">
            <AdminSidebar v-if="$route.path!='/admin/login'" />
            <div :class="$route.path!='/admin/login'?'wrapper':''" class="w-full">
                <router-view></router-view>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Nav from './components/nav.vue'
import AdminNav from './components/Admin/Nav.vue'
import AdminSidebar from './components/Admin/SideBar.vue'
import Footer from './components/footer.vue'

export default {
    components: {
        Nav,
        AdminNav,
        AdminSidebar,
        Footer
    },
    data() {
        return {
            a: '',
            name: 'user',
        }
    },
    computed: {
        me() {
            return this.$store.state.me
        }
    },
    watch: {
        '$route.path'(val) {
            var a = val.split('/')
            if (a.length > 1 && a[1] == 'admin') {
                this.name = 'admin'
            } else {
                this.name = 'user'
            }
        }
    },
    mounted() {
        this.$store.dispatch("get_statpages");
        var a = this.$route.path.split('/')
        if (a.length > 1 && a[1] == 'admin') {
            this.name = 'admin'
        } else {
            this.name = 'user'
        }

    }
}
</script>

<style lang="scss">
:root {
    --black: #454851;
    --white-black: rgba(69, 72, 81, 0.8);
    --white-color: #fff;
    --other-black: #454851;
    --grey-black: #909090;
    --white-bg: #fff;
    --color-blue: #00B5EC;
    --blue-bg: #00B5EC;
    --white-gray-bg: #F8F8F8;
    --white-blue-bg: #9EE1F6;
    --bg-stock: #fff;
    --bg-top: #F8F8F9;
    --button-gray: rgba(192, 189, 189, 0.3);
}

.white-bg {
    background: var(--white-bg);
}

* {
    box-sizing: border-box !important;
}

#app {
    background: var(--black);
}

.minw-100 {
    min-width: 100vh;
}

.wrapper {
    flex: 1;
    padding: 20px;
    background: var(--bg-top);
    min-height: calc(100vh - 60px);
    overflow: hidden;
}

.admin-view {
    max-width: 100%;
    overflow: hidden;
}

.scleton-image-avior {
    width: 100%;
    height: 100vh;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;

}

body {
    font-family: Stem-Medium;
}

.w-full {
    width: 100%;
}

.flex {
    display: flex !important;
}

.justify-center {
    justify-content: center !important;
}

.justify-between {
    justify-content: space-between !important;
}

.justify-end {
    justify-content: flex-end !important;
}

.justify-start {
    justify-content: flex-start !important;
}

.fw-w {
    flex-wrap: wrap;
}

.items-center {
    align-items: center !important;
}

.mw-1920 {
    max-width: 1920px;
}

.mw-1440 {
    max-width: 1440px;
}

.phone {
    display: none;
}

.tac {
    text-align: center;
}

.tal {
    text-align: left;
}

.pt-0 {
    padding-top: 0px !important;
}

.pt-40 {
    padding-top: 40px !important;
}

.pb-40 {
    padding-bottom: 40px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pb-0 {
    padding-bottom: 0px !important;
}

.ml-10 {
    margin-left: 10px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-10 {
    margin-right: 10px;
}

.pt-200 {
    padding-top: 200px !important;
}

.pt-150 {
    padding-top: 150px;
}

.z-1 {
    z-index: 1;
}

.pr {
    position: relative;
}

.el-notification__title,
.el-notification__content {
    font-family: Stem-Regular !important;
}

.mb-20px {
    margin-bottom: 20px !important;
}

.mb-30px {
    margin-bottom: 30px !important;
}

.op8 {
    opacity: 0.8 !important;
}

.disabled_button {
    opacity: 0.6 !important;
    cursor: not-allowed !important;
}

.title-w500 {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 90%;
    white-space: nowrap;
}

a { 

    button {
        margin-top: 0 !important;
        margin-right: 0 !important;
        margin: 0 !important;
    }

    .button {
        margin-top: 0 !important;
        margin-right: 0 !important;
        margin: 0 !important;
    }
}

@keyframes animeOpacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.opacity-anime {
    animation-name: animeOpacity;
    animation-duration: 3s;
    animation-timing-function: ease-in;

}

@media screen and(max-width:768px) {
    .pt-200 {
        padding-top: 100px !important;
    }

    .pt-150 {
        padding-top: 50px;
    }

    .pt-70 {
        padding-top: 70px;
    }

    .app-nav {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
    }

    .phone {
        display: block;
    }

    .desktop {
        display: none;
    }
}

@font-face {
    font-family: Stem-Medium;
    src: url("./assets/fonts/Stem-Medium.otf") format("opentype");
}

@font-face {
    font-family: Stem-Regular;
    src: url("./assets/fonts/Stem-Regular.otf") format("opentype");
}

@font-face {
    font-family: Stem-Bold;
    src: url("./assets/fonts/Stem-Bold.otf") format("opentype");
}

#app {
    font-family: Stem-Regular;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-feature-settings: "tnum"on, "lnum"on;
}

.page-item {
    font-family: Stem-Regular;
    font-size: 1.125rem;
    line-height: 27px;
    font-feature-settings: "tnum"on, "lnum"on;
    color: var(--white-black);

    a {
        opacity: 1 !important;
        color: var(--color-blue) !important;
    }

    li {
        margin-top: 10px;
    }

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.button {
    border: none;
    background: var(--black);
    color: var(--white-color);
    padding: 12px 30px;
    outline: none;
    font-family: Stem-Medium;
    font-size: 1.125rem;
    line-height: 1.25rem;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in !important;
    border: 1px solid #454851;

    &:hover {
        background: unset !important;
        color: #454851 !important;
    }

    &.default {
        color: var(--other-black);
        padding: 0;
        background: none;
    }

    &.gray {
        background: var(--button-gray) !important;
        color: #454851;
        border: none;
    }

    &.gray:hover {
        background: var(--button-gray) !important;
        color: #454851;

    }

    &.default:focus {
        background: unset !important;
    }

    &.default:hover {
        background: unset !important;
    }

    &.blue {
        padding: 12px 30px;
        color: var(--white-color);
        background: #00B5EC;
        border: 1px solid #00B5EC;

    }

    &.blue:hover {
        background: unset;
        color: #00B5EC !important;

    }

    &.ink {
        background: #44405C;
    }

    &.save {
        background: #67C23A;
    }

}

.ql-tooltip {
    left: 0 !important;
}

p {
    margin: 0;
    font-family: Stem-Medium;
    font-weight: 400;

}

h1 {
    margin: 0px;
    font-family: Stem-Medium;
    font-weight: 400;

}

.td-none {
    a {
        text-decoration: unset !important;
    }
}

.nav-link a {
    text-decoration: unset !important;
}

.el-dropdown-menu__item a {
    text-decoration: unset;
}

.technique-pogination {
    .number {
        font-family: Stem-Medium;
        font-size: 1rem;
        line-height: 1.75rem;
        color: var(--white-black);
        width: 30px;
        height: 30px;

    }

    .el-pager li.active {
        background: #00B5EC !important;
        border-radius: 4px;
        color: var(--white-color);
    }

    .el-pagination button:disabled {
        background: var(--white-bg) !important;
    }

    .el-pager li {
        background: var(--white-bg) !important;
    }

    .el-pagination .btn-next,
    .el-pagination .btn-prev {
        background: center center no-repeat var(--white-bg) !important;
    }
}

.el-date-table th, 
.el-date-picker__header-label {
    font-family: Stem-Medium !important;
}

.el-date-table td span {
    font-family: Stem-Regular !important;
}

.el-dropdown-menu {
    border-radius: 10px !important;
}

.display-none {
    display: none;
}

.mw-1440 {
    max-width: 1440px;
}

.pr-20px {
    padding-right: 20px;
}

.mt-20px {
    margin-top: 20px;
}

.mt-40px {
    margin-top: 40px;
}

.w-50 {
    width: 50%;
}

.bg-gray {
    background: var(--white-gray-bg);
}

.bg-gray-light {
    background: var(--bg-top);
}

.error-p {
    font-size: 0.75rem;
    color: red;
    position: absolute;
    left: 0;
    bottom: 0;
}

//  TABLE
.el-table__header th {
    font-family: Stem-Medium;
    font-size: 1rem;
    color: var(--other-black);
    line-height: 1.125rem;
}

.el-table__body-wrapper td {
    font-family: Stem-Regular;
    font-size: 0.875rem;
    line-height: 1rem;
    color: var(--other-black);

    button {
        padding: 8px 16px;
        color: var(--white-color);
        border: none;
        background: #44405C;
        cursor: pointer;
        outline: none;

        &.delete {
            background: #F56C6C;
        }

        &.default {
            border: 1px solid #DCDFE6;
            color: #606266;
            background: var(--white-bg);
        }
    }
}

.table-lang-button {
    padding: 8px 16px;
    color: var(--white-color);
    border: none;
    background: #44405C;
    cursor: pointer;
    outline: none;
    border-radius: 5px;

    &.active {
        background: #736d92;
    }

}

.el-table tr {
    border-color: #DDDFE2;
}

.table-pr-20px {
    padding-right: 20px !important;
}

.table-pl-20px {
    padding-left: 20px !important;
}

.item {
    margin-bottom: 20px;

    p {
        font-family: Stem-Regular;
        font-size: 1.125rem;
        line-height: 1.25rem;
        color: var(--white-black);
        margin-bottom: 5px;

    }

    .el-checkbox__label {
        font-family: Stem-Regular;
        font-size: 1rem;
        line-height: 1rem;
        color: var(--white-black);
    }

    .el-input__inner,
    .el-textarea__inner {
        font-family: Stem-Regular;
        font-size: 1rem;
        line-height: 1.125rem;
        height: auto;
        padding: 8px 20px 10px 20px;
    }
}

.owf-h {
    overflow: hidden !important;
}

.top-100 {
    transform: translateY(-300%);
}

.time-03 {
    transition: all 0.3s ease-in;
}

@keyframes left-right {
    from {
        transform: translateX(-120%);
    }

    to {
        transform: translateX(0%);
    }
}

// MODAL
.modal {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    position: fixed;
    align-items: center;
    justify-content: center;
    padding: 20px;
    transition: all 05s ease-in;
    display: none;

    &.active {
        display: flex !important;
    }

    .modal-item {
        position: relative;
        z-index: 1;
    }

    .modal-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        background: black;
        opacity: 0.5;
    }
}

.contact-card {
    padding: 40px;
    max-width: 570px;
    overflow: hidden;
    width: 100%;
    box-shadow: 0px 6px 16px -8px rgba(0, 72, 153, 0.12), 0px 9px 28px rgba(0, 72, 153, 0.05), 0px 12px 48px 16px rgba(0, 72, 153, 0.03);
    border-radius: 10px;
    position: relative;
    z-index: 1;
    background: var(--white-bg);

    h1 {
        font-family: Stem-Regular;
        font-size: 20px;
        line-height: 1.5rem;
        color: var(--other-black);
    }

    .contact-card-input {

        width: 100%;

        .el-input__inner {
            border: 1.5px solid #DDDFE2;
            box-sizing: border-box;
            border-radius: 4px;
            padding: 18px 30px;
            font-family: Stem-Regular;
            font-size: 1.125rem;
            line-height: 1.25rem;
            height: auto;
            color: var(--white-black);
            margin-top: 25px;
        }
    }

    .contact-card-text-area {
        margin-top: 0px;
        width: 100%;

        .el-textarea__inner {
            padding: 18px 30px;
            font-family: Stem-Regular;
            font-size: 1.125rem;
            line-height: 1.25rem;
            height: auto;
            color: var(--white-black);
            border: 1.5px solid #DDDFE2;
            box-sizing: border-box;
            border-radius: 4px;
        }
    }

    .is-error {

        .el-textarea__inner,
        .el-input__inner {
            color: #F56C6C !important;
            border: 1.5px solid #F56C6C !important;
        }

    }

    button {
        margin-top: 40px;
    }

}

// Animations
.btn-4 {
    overflow: hidden;
    position: relative;

    span {
        z-index: 20;
    }

    &:after {
        background: var(--white-bg);
        content: "";
        height: 250px;
        left: -150px;
        opacity: .2;
        position: absolute;
        top: -125px;
        transform: rotate(45deg);
        transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        width: 100px;
        z-index: 0;
    }

}

.btn-4:hover {

    &:after {
        left: 130%;
        transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }
}

// Colors
.c-blue {
    color: #00B5EC !important;
}

// BUTTON
$dark-blue: #222;
$green: #90feb5;
$action-color: $green;

.anime-button-div {
    display: block;
    height: 100%;
    animation: hue-rotate 10s linear infinite;
}

.anime-button {
    -webkit-font-smoothing: antialiased;
    background-color: $dark-blue;
    border: none;
    color: var(--white-color);
    display: inline-block;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.875rem;
    font-weight: 100;
    text-decoration: none;
    user-select: none;
    letter-spacing: 1px;
    color: white;
    padding: 20px 40px;
    text-transform: uppercase;
    transition: all 0.1s ease-out;

    &:hover {
        background-color: $action-color;
        color: var(--white-color);
    }

    &:active {
        transform: scale(0.95);
    }

    &--bubble {
        position: relative;
        z-index: 2;
        color: white;
        background: none;

        &:hover {
            background: none;
        }

        &:hover+.button--bubble__effect-container .circle {
            background: darken($action-color, 15%);
        }

        &:hover+.button--bubble__effect-container .button {
            background: darken($action-color, 15%);
        }

        &:active+.button--bubble__effect-container {
            transform: scale(0.95);
        }

        &__container {
            position: relative;
            display: inline-block;

            .effect-button {
                position: absolute;
                width: 50%;
                height: 25%;
                top: 50%;
                left: 25%;
                z-index: 1;
                transform: translateY(-50%);
                background: $dark-blue;
                transition: background 0.1s ease-out;
            }
        }
    }
}

.button--bubble__effect-container {
    position: absolute;
    display: block;
    width: 200%;
    height: 400%;
    top: -150%;
    left: -50%;
    -webkit-filter: url("#goo");
    filter: url("#goo");
    transition: all 0.1s ease-out;
    pointer-events: none;

    .circle {
        position: absolute;
        width: 25px;
        height: 25px;
        border-radius: 15px;
        background: $dark-blue;
        transition: background 0.1s ease-out;

        &.top-left {
            top: 40%;
            left: 27%;
        }

        &.bottom-right {
            bottom: 40%;
            right: 27%;
        }
    }
}

.goo {
    position: absolute;
    visibility: hidden;
    width: 1px;
    height: 1px;
}

.button--bubble__container {
    top: 50%;
    margin-top: -25px;
}

@keyframes hue-rotate {
    from {
        -webkit-filter: hue-rotate(0);
        -moz-filter: hue-rotate(0);
        -ms-filter: hue-rotate(0);
        filter: hue-rotate(0);
    }

    to {
        -webkit-filter: hue-rotate(360deg);
        -moz-filter: hue-rotate(360deg);
        -ms-filter: hue-rotate(360deg);
        filter: hue-rotate(360deg);
    }
}
</style>
