<template>
<div class="flex justify-center w-full nav-div td-none" :class="scrollY>60?'nav-scroll':''" id="nav">
    <div class="nav mw-1920 w-full" :class="nav?'nav-active':''" v-if="!loader">
        <div class="flex justify-between items-center phone-w-full">
            <div class="nav-phone">
                <div class="logo-img">
                    <a href="/">
                    <img :src="navPage.files[`siteLogo_${$i18n.locale}`]? 'https://avior.prestigeautofl.com'+navPage.files[`siteLogo_${$i18n.locale}`]:require('../assets/icons/logo.svg')" alt=""> 
                    </a>
                </div>
                <div class="burger">
                    <button @click="nav=!nav" :class="nav?'burger-active':''">
                        <div class="burger-item1"></div>
                        <div class="burger-item2"></div>
                        <div class="burger-item3"></div>
                    </button>
                </div>
            </div>
            <div class="nav-linkes " :class="nav?'nav-linkes-active':''" >
                
                  
                <div class="nav-info-phone phone-show" :class="phoneuser?'nav-info-phone-active':''" v-if="me.id">
                    <div class="nav-info-top" @click="phoneuser=!phoneuser">
                        <p> {{me.f_name ? me.f_name:me.email}}  </p>
                        <img src="@/assets/icons/down.svg" alt="AAA">
                    </div>
                     
                    <el-collapse-transition>
                        <div class="nav-info-linkes" v-show="phoneuser">
                            <router-link tag="a" to="/me" v-if="faq.id" active-class="nav-link-active" exact>
                                <div class="nav-info-link" @click="nav=false; phoneuser=false;">
                                    <h1> {{navPage.data[`accaunt_${$i18n.locale}`]}} </h1> 
                                </div>
                            </router-link> 
                            <router-link tag="a" to="/me/credit" v-if="faq.id">
                                <div class="nav-info-link" @click="nav=false;phoneuser=false;">
                                    <h1> {{navPage.data[`credit_${$i18n.locale}`]}} </h1> 
                                </div>
                            </router-link> 
                            <router-link tag="a" to="/me/history" v-if="faq.id">
                                <div class="nav-info-link" @click="nav=false;phoneuser=false;">
                                    <h1> {{navPage.data[`history_${$i18n.locale}`]}} </h1> 
                                </div>
                            </router-link> 
                            <router-link tag="a" to="/me/message" v-if="faq.id">
                                <div class="nav-info-link" @click="nav=false;phoneuser=false;">
                                    <h1> {{navPage.data[`message_${$i18n.locale}`]}}</h1> 
                                </div>
                            </router-link> 
                            <div @click="logout(); nav=false;">
                                <div  class="nav-info-link"  >
                                    <h1> {{navPage.data[`logout_${$i18n.locale}`]}}</h1> 
                                </div> 
                            </div> 
                            
                        </div>
                    </el-collapse-transition>
                </div>


                <div class="nav-link" @click="nav=false"  >
                    <router-link tag="a" to="/"  :class="$route.name=='home'?'nav-link-active':''"  exact>
                        <p> {{   home.data.elements[`title_${$i18n.locale}`] }} </p>
                         <div class="nav-link-hover"></div>
                    </router-link>     
                </div> 
                <div class="nav-link" @click="nav=false"  >
                    <router-link tag="a" to="/credit" active-class="nav-link-active" exact>
                        <p> {{credit.data[`name_${$i18n.locale}`]}} </p>
                        <div class="nav-link-hover"></div>
                    </router-link>
                    
                </div>
                   <div class="nav-link" @click="nav=false" v-if="leasing.id">
                    <router-link tag="a" to="/leasing" active-class="nav-link-active" >
                        <p> {{leasing.data[`name_${$i18n.locale}`]}}</p>
                        <div class="nav-link-hover"></div>
                    </router-link>
                    
                </div>
                <div class="nav-link" @click="nav=false">
                    <router-link tag="a" to="/stocks" active-class="nav-link-active" >
                        <p> {{stock.data[`name_${$i18n.locale}`]}} </p>
                         <div class="nav-link-hover"></div>
                    </router-link>
                    
                </div>
                
                <div class="nav-info-phone phone-show" :class="phoneInfo?'nav-info-phone-active':''">
                    <div class="nav-info-top" @click="phoneInfo=!phoneInfo">
                        <p> {{navPage.data[`information_${$i18n.locale}`]}}  </p>
                        <img src="@/assets/icons/down.svg" alt="AAA">
                    </div>
                     
                    <el-collapse-transition>
                        <div class="nav-info-linkes" v-show="phoneInfo">
                            <router-link tag="a" to="/faq" v-if="faq.id">
                                <div class="nav-info-link" @click="nav=false">
                                    <h1> {{ faq.data[`name_${$i18n.locale}`] }} </h1>
                                    <p>{{ faq.data[`link_dec_${$i18n.locale}`] }}</p>
                                </div>
                            </router-link>
                            <router-link tag="a" to="/procompany">
                                <div class="nav-info-link" @click="nav=false"> 
                                    <h1>{{company.data[`name_${$i18n.locale}`]}}</h1>
                                                    <p>{{company.data[`link_dec_${$i18n.locale}`]}}</p>
                                </div>
                            </router-link>
                            <router-link tag="a" to="/contact" v-if="contact.id">
                                <div class="nav-info-link" @click="nav=false">
                                    <h1> {{contact.data[`name_${$i18n.locale}`]}} </h1>
                                    <p> {{contact.data[`link_dec_${$i18n.locale}`]}} </p>
                                </div>
                            </router-link>
                            <router-link tag="a" to="/advantages" v-if="advantage.id">
                                <div class="nav-info-link" @click="nav=false">
                                    <h1> {{advantage.data[`name_${$i18n.locale}`]}} </h1>
                                    <p>{{advantage.data[`link_dec_${$i18n.locale}`]}}</p>
                                </div>
                            </router-link>
                            <router-link tag="a" to="/credit"  >
                                <div class="nav-info-link" @click="nav=false">
                                    <h1>{{credit.data[`name_${$i18n.locale}`]}}</h1>
                                    <p>{{credit.data[`link_dec_${$i18n.locale}`]}}</p>
                                </div>
                            </router-link>
                            <router-link tag="a"  v-for="(item, i) in page" :key="i" :to="`/page/${item.url}`">
                                <div class="nav-info-link" @click="nav=false">
                                    <h1> {{item.url_name_ua}} </h1>
                                    <p> {{item.url_description_ua}} </p>
                                </div>
                            </router-link>
                        </div>
                    </el-collapse-transition>
                </div>
                 <div class="nav-info-phone phone-show" :class="modep?'eye-phone-active':''">
                    <div class="nav-info-top" @click="modep=!modep">
                        <p>{{navPage.data[`set_name_${$i18n.locale}`]}}  </p>
                        <img src="@/assets/icons/down.svg" alt="AAA">
                    </div>
                     
                    <el-collapse-transition>
                         <div class="eye-phone" v-show="modep">
                             <h1>{{navPage.data[`set_title_${$i18n.locale}`]}}</h1>
                             <div class="eye-phone-items">

                                 <div class="eye-phone-item">
                                     <div class="eye-phone-button" @click="modet_value=1">
                                         <img src="@/assets/icons/smalt.svg" alt="" :class="modet_value==1?'filter-blue':''">
                                     </div>
                                 </div>
                                 <div class="eye-phone-item">
                                     <div class="eye-phone-button" @click="modet_value=2">
                                         <img src="@/assets/icons/defaultt.svg" alt="" :class="modet_value==2?'filter-blue':''">
                                     </div>
                                 </div>
                                 <div class="eye-phone-item">
                                     <div class="eye-phone-button" @click="modet_value=3">
                                         <img src="@/assets/icons/bigt.svg" alt="" :class="modet_value==3?'filter-blue':''">
                                     </div>
                                 </div>
                                 <div class="eye-phone-item">
                                     <div class="eye-phone-button" @click="moden_value=!moden_value">
                                         <img src="@/assets/icons/night.svg" alt="" :class="moden_value==true?'filter-blue':''">
                                     </div>
                                 </div>
                             </div>
                             <div class="eye-phone-bottom">
                                 <button @click="saveMode()">{{navPage.data[`set_submit_${$i18n.locale}`]}}</button>
                                 <p @click="resetMode()">{{navPage.data[`set_reset_${$i18n.locale}`]}} </p>
                             </div>
                         </div>
                    </el-collapse-transition>
                </div>

                <div v-if="!me.id" class="nav-link phone" @click="nav=false; $router.push('/login')">
                    <p> {{navPage.data[`login_${$i18n.locale}`]}} </p>
                </div>
                <div class="nav-phone-langs phone-show">
                    <button :class="$i18n.locale=='ua'?'active-lang':''" @click="changeLang('ua')">UA</button>
                    <button :class="$i18n.locale=='en'?'active-lang':''" @click="changeLang('en')">EN</button>
                </div>
                <div class="nav-link desktop-show">
                    <el-dropdown trigger="click" class="link-dropdown" placement="top-start">
                        <el-button class="button default el-dropdown-link">
                            <div class="flex items-center">
                                <p> {{$t('information')}}  </p><img class="ml-10" style="margin-top:3px;" src="@/assets/icons/down.svg" alt="AAA">
                            </div>
                        </el-button>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <div class="dual-dropdown-menu">
                                    <el-dropdown-item>
                                        <router-link tag="a" to="/faq">
                                            <div class="dropdown-link">
                                                <img src="@/assets/icons/faq.svg" alt="">
                                                <div class="link-text">
                                                    <h1>{{ faq.data[`name_${$i18n.locale}`] }}</h1>
                                                    <p>{{ faq.data[`link_dec_${$i18n.locale}`] }}</p>
                                                </div>
                                            </div>
                                        </router-link>
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="advantage.id">
                                        <router-link tag="a" to="/advantages">
                                            <div class="dropdown-link">
                                                <img src="@/assets/icons/prevagi.svg" alt="">
                                                <div class="link-text">
                                                    <h1> {{advantage.data[`name_${$i18n.locale}`]}} </h1>
                                                    <p>{{advantage.data[`link_dec_${$i18n.locale}`]}}</p>
                                                </div>
                                            </div>
                                        </router-link>
                                    </el-dropdown-item>
                                </div>
                                <div class="dual-dropdown-menu">
                                    <el-dropdown-item>
                                        <router-link tag="a" to="/procompany">
                                            <div class="dropdown-link">
                                                <img src="@/assets/icons/puplegroup.svg" alt="">
                                                <div class="link-text">
                                                    <h1>{{company.data[`name_${$i18n.locale}`]}}</h1>
                                                    <p>{{company.data[`link_dec_${$i18n.locale}`]}}</p>
                                                </div>
                                            </div>
                                        </router-link>
                                    </el-dropdown-item>
                                    <el-dropdown-item >
                                        <router-link tag="a" to="/credit">
                                            <div class="dropdown-link">
                                                <img src="@/assets/icons/payment.svg" alt="">
                                                <div class="link-text"> 
                                                    <h1>{{credit.data[`name_${$i18n.locale}`]}}</h1>
                                                    <p>{{credit.data[`link_dec_${$i18n.locale}`]}}</p> 
                                                </div>
                                            </div>
                                        </router-link>
                                    </el-dropdown-item> 
                                     
                                </div>
                                <div class="child-dropdown-menu" >
                                    <el-dropdown-item v-if="me.id">
                                        <router-link tag="a" to="/contact">
                                            <div class="dropdown-link">
                                                <img src="@/assets/icons/contact.svg" alt="">
                                                <div class="link-text">
                                                    <h1>{{contact.data[`name_${$i18n.locale}`]}}</h1>
                                                    <p>{{contact.data[`link_dec_${$i18n.locale}`]}}</p>
                                                </div>
                                            </div>
                                        </router-link>
                                    </el-dropdown-item>
                                     <el-dropdown-item v-for="(item, i) in page" :key="i">
                                        <router-link tag="a" :to="`/page/${item.url}`">
                                            <div class="dropdown-link">
                                                <img  :src="`https://avior.prestigeautofl.com/${item.icon}`" alt="">
                                                <div class="link-text">
                                                    <h1> {{item.url_name_ua}} </h1>
                                                    <p> {{item.url_description_ua}} </p>
                                                </div>
                                            </div>
                                        </router-link>
                                    </el-dropdown-item>
                                </div>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </div>
            
        </div>
        <div class="user-controls"> 
            <div class="eye-control" v-click-outside="hide">
                <div class="eye-button" @click="mode=!mode" :class="mode?'active':''">
                    <img src="@/assets/icons/eye.svg" alt="">
                </div>
                <el-collapse-transition>
                <div class="eye-controls" v-show="mode">
                    <h1>{{navPage.data[`set_title_${$i18n.locale}`]}}</h1>
                    <div class="eye-control-buttons">
                        <div class="eye-control-buttons-item">
                            <div class="eye-img" @click="modet_value=1">
                                <img src="@/assets/icons/smalt.svg" alt="" :class="modet_value==1?'filter-blue':''">
                            </div>
                        </div>
                        <div class="eye-control-buttons-item">
                            <div class="eye-img" @click="modet_value=2">
                                <img src="@/assets/icons/defaultt.svg" alt="" :class="modet_value==2?'filter-blue':''">
                            </div>
                        </div>
                        <div class="eye-control-buttons-item">
                            <div class="eye-img" @click="modet_value=3">
                                <img src="@/assets/icons/bigt.svg" alt="" :class="modet_value==3?'filter-blue':''">
                            </div>
                        </div>
                        <div class="eye-control-buttons-item">
                            <div class="eye-img" @click="moden_value=!moden_value">
                                <img src="@/assets/icons/night.svg" alt="" :class="moden_value==true?'filter-blue':''">
                            </div>
                        </div>
                    </div>
                    <div class="eye-bottom">
                        <button @click="saveMode()">{{navPage.data[`set_submit_${$i18n.locale}`]}}</button>
                        <p @click="resetMode()">{{navPage.data[`set_reset_${$i18n.locale}`]}}</p>
                    </div>
                </div>
                </el-collapse-transition>
            </div>
            <el-dropdown trigger="click" class="user-dropdown" v-if="me.id">
                <el-button class="dropdown-button el-dropdown-link">
                    <p class="username-p"> {{me.f_name ? me.f_name:me.email}} <i class="el-icon-arrow-down el-icon--right"></i> </p>
                </el-button>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item>
                            <router-link tag="div" to="/me">
                                <p>{{navPage.data[`accaunt_${$i18n.locale}`]}}</p>
                            </router-link>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <router-link tag="div" to="/me/credit">
                                <p>{{navPage.data[`credit_${$i18n.locale}`]}}</p>
                            </router-link>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <router-link tag="div" to="/me/history">
                                <p>{{navPage.data[`history_${$i18n.locale}`]}}</p>
                            </router-link>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <router-link tag="div" to="/me/message">
                                <p>{{navPage.data[`message_${$i18n.locale}`]}}</p>
                            </router-link>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <div @click="logout()">
                                <p> {{navPage.data[`logout_${$i18n.locale}`]}}  </p>
                            </div>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
            <button class="button" @click="$router.push('/login')" v-else>Увійти</button>
            <el-dropdown trigger="click" class="lang-dropdown">
                <el-button class="dropdown-button el-dropdown-link">
                    <p> {{ $i18n.locale=='en'? 'EN': 'UA' }}<i class="el-icon-arrow-down el-icon--right"></i> </p>
                </el-button>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item>
                            <p  @click="changeLang('ua')" >UA</p>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <p  @click="changeLang('en')" >EN</p>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>

        </div>  
        
    </div>
    <div class="nav mw-1920 w-full" :class="nav?'nav-active':''" v-if="loader">
        <div class="flex justify-between items-center phone-w-full">
             
            <div class="nav-linkes " :class="nav?'nav-linkes-active':''" >
                <div class="nav-link" @click="nav=false"  >
                    <el-skeleton style="width: 150px">
                        <template slot="template">  
                            <el-skeleton-item variant="p" style="width: 100%" />
                            
                        </template>
                    </el-skeleton>  
                </div> 
                <div class="nav-link" @click="nav=false">
                    <el-skeleton style="width: 150px">
                        <template slot="template">  
                            <el-skeleton-item variant="p" style="width: 100%" />
                            
                        </template>
                    </el-skeleton>  
                    
                </div>
                   <div class="nav-link" @click="nav=false">
                    <el-skeleton style="width: 150px">
                        <template slot="template">  
                            <el-skeleton-item variant="p" style="width: 100%" />
                            
                        </template>
                    </el-skeleton>  
                    
                </div>
                <div class="nav-link" @click="nav=false">
                    <el-skeleton style="width: 150px">
                        <template slot="template">  
                            <el-skeleton-item variant="p" style="width: 100%" />
                        </template>
                    </el-skeleton>  
                    
                </div> 
            </div>
            
        </div>
        <div class="user-controls flex"> 
              <el-skeleton style="width: 150px">
                        <template slot="template">  
                            <el-skeleton-item variant="p" style="width: 80%" />
                        </template>
                    </el-skeleton> 
              <el-skeleton style="width: 150px">
                        <template slot="template">  
                            <el-skeleton-item variant="p" style="width: 80%" />
                        </template>
                    </el-skeleton> 

        </div>  
        
    </div>
     
</div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
export default {
    data() {
        return {
            nav: false,
            phoneInfo: false,
            scrollY: 0,
            page: [], 
            mode:false,
            modep:false,
            phoneuser:false,
            a:'10px',
            modet:2,
            modet_value:0,
            moden_value:0
        }
    },
    computed: {
        me() {
            return this.$store.state.me
        },
        navPage(){
            return this.$store.state.Nav
        },
        home(){
            return this.$store.state.Home
        },
        stock(){
            return this.$store.state.Stock
        },
        credit(){
            return this.$store.state.Credit
        }, 
        contact(){
            return this.$store.state.Contact
        },
        faq(){
            return this.$store.state.FAQ
        },
        company(){
            return this.$store.state.Company
        },
        leasing(){
            return this.$store.state.Leasing
        },
        advantage(){ 
            return this.$store.state.Advantages
          
        },
        pages() {
            return this.$store.state.Pages
        },
        loader(){
           return this.$store.state.loader
        }
    },
    watch: {
        pages() {
            this.page = this.pages.filter(a => {
                return a.nav == true
            })
        },
        
         
    },
    methods: {
        changeLang(lang){
            this.$i18n.locale=lang
            localStorage.lang=lang
            this.nav=false
        },
        logout() {
            this.$store.commit('get_me', {
                payload: {}
            })
            localStorage.month=null
            localStorage.count=null
            localStorage.token = null
            localStorage.role = null
            this.$router.push('/login')
        },
        hide(){
            this.mode=false; 
        },
        saveMode(){
            if(this.modet_value==1){
                document.documentElement.style.fontSize="10px" 
            }
            if(this.modet_value==2){
                document.documentElement.style.fontSize="18px"
            }
            if(this.modet_value==3){
                document.documentElement.style.fontSize="20px"
            }
            console.log(this.modet_value)
            if(this.moden_value==true){
                document.documentElement.style.setProperty('--black', 'rgb(255, 255, 255)')
                document.documentElement.style.setProperty('--white-black', 'rgb(200, 200, 200)')
                document.documentElement.style.setProperty('--white-color', 'rgb(50, 50, 50)')
                document.documentElement.style.setProperty('--other-black', 'rgb(220, 220, 220)')
                document.documentElement.style.setProperty('--grey-black', '#fff')
                document.documentElement.style.setProperty('--white-bg', 'rgb(20, 20, 20)') 
                document.documentElement.style.setProperty('--white-gray-bg', 'rgb(50, 50, 50)') 
                document.documentElement.style.setProperty('--bg-stock', 'grey')
                document.documentElement.style.setProperty('--bg-top', 'grey')
                document.documentElement.style.setProperty('--button-gray', '#fff')
            }
            if(this.moden_value==false){
                document.documentElement.style.setProperty('--black', '#454851')
                document.documentElement.style.setProperty('--white-black', 'rgba(69, 72, 81, 0.8)')
                document.documentElement.style.setProperty('--white-color', '#fff')
                document.documentElement.style.setProperty('--other-black', '#454851')
                document.documentElement.style.setProperty('--grey-black', '#909090')
                document.documentElement.style.setProperty('--white-bg', '#fff') 
                document.documentElement.style.setProperty('--white-gray-bg', '#F8F8F8') 
                document.documentElement.style.setProperty('--bg-stock', '#fff')
                document.documentElement.style.setProperty('--bg-top', '#F8F8F9') 
                document.documentElement.style.setProperty('--button-gray', 'rgba(192, 189, 189, 0.3)')
            }
        },
        resetMode(){
                this.modet_value=0
                this.moden_value=false
                document.documentElement.style.setProperty('--black', '#454851')
                document.documentElement.style.setProperty('--white-black', 'rgba(69, 72, 81, 0.8)')
                document.documentElement.style.setProperty('--white-color', '#fff')
                document.documentElement.style.setProperty('--other-black', '#454851')
                document.documentElement.style.setProperty('--grey-black', '#909090')
                document.documentElement.style.setProperty('--white-bg', '#fff') 
                document.documentElement.style.setProperty('--white-gray-bg', '#F8F8F8') 
                document.documentElement.style.setProperty('--bg-stock', '#fff')
                document.documentElement.style.setProperty('--bg-top', '#F8F8F9')
                document.documentElement.style.setProperty('--button-gray', 'rgba(192, 189, 189, 0.3)')
                document.documentElement.style.fontSize="16px"
        }
    },
    mounted() { 
        this.$i18n.locale=localStorage.lang ? localStorage.lang:'ua'
        window.addEventListener('scroll', () => {
            this.scrollY = Math.round(window.scrollY);
        });
        if (this.$route.name != 'login' && localStorage.token != null) {
            this.$store.dispatch("get_me");
            
        }
        this.$store.dispatch("get_pages");
        
        this.page = this.pages.filter(a => {
            return a.nav == true
        })


        
    },
     directives: {
    ClickOutside
  },
 
  }
 
</script>

<style lang="scss">

 
* {
    box-sizing: border-box;
}

body {
    padding: 0;
    margin: 0;
}

.nav-phone {
    width: auto;
    display: block;
    padding: 0px;

}

.nav-div {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.nav {
    width: 100%;

    transition: padding 0.1s ease-in;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 110px;

    .logo-img {
        margin-right: 100px;

        img {
            width: 150px;
            max-height: 45px;
        }
    }
    .nav-linkes {

        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .nav-link {
            margin-right: 30px;
            padding: 10px 0px;
            position: relative;
            cursor: pointer; 
            text-decoration: unset !important;
            a {
                text-decoration: unset !important;

            }

            p {
                font-family: Stem-Regular;
                font-size: 1.125rem;
                line-height: 1.25rem;
                display: flex;
                align-items: center;
                color: var(--black);
                font-weight: 400;
                margin: 0; 
                 
            }
            .nav-link-hover{
                position: absolute;
                bottom: 0;
                left: 0;
                height: 3px;
                width: 100%;
                
                display: flex;
                justify-content: center;

                &::before{
                    content: "";
                    width: 0%;
                    height: 100%;
                    background: var(--black);
                    border-radius: 5px;
                }
            }
            &:hover{
                .nav-link-hover{ &::before{ width: 100% !important; transition: all 0.2s ease-in; }}
                
            }
            

        }

        .nav-link-active  {
            h1{
                font-weight: 600;
                font-family: Stem-Medium;
            }
            p{
                font-weight: 600;
                font-family: Stem-Medium;
            }
            .nav-link-hover{ &::before{ width: 100% !important; transition: all 0.2s ease-in; }}
            
            
        }
    }
    .user-controls{
        min-width: 300px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .lang-dropdown {
        margin-left: 30px;

        .dropdown-button {
            background: none;
            padding: 0;
            outline: none;
            font-family: Stem-Medium;
            border: none;
            font-size: 1rem;
            line-height: 1.125rem;
            display: flex;
            align-items: center;
            color: var(--color-blue);
            cursor: pointer;

            p {
                margin: 0;
            }
        }

    }

}
 
.nav-scroll {
    .nav {
        padding-top: 20px;
        padding-bottom: 20px;
        background: var(--white-bg);
        box-shadow: 0px 0px 6px rgba(0, 72, 153, 0.12);

    }
}

.link-dropdown {
    button {
        padding: 0;
        border: none;
    }
}

.burger {
    display: none;
}

.dual-dropdown-menu {
    display: flex;
    padding: 0px 10px; 

    .el-dropdown-menu__item {
        padding: 0px 10px !important; 
    }

    .el-dropdown-menu__item:hover {
        background: unset !important;
    }

}
.child-dropdown-menu{
     display: flex;
    padding: 0px 10px;
    flex-wrap: wrap;
    max-width: 560px;

    .el-dropdown-menu__item {
        padding: 0px 10px !important;  
    }

    .el-dropdown-menu__item:hover {
        background: unset !important;
    }
}

.dropdown-link {
    width: 250px;
    display: flex;
    align-items: center;
    border-bottom: 1.5px solid #DDDFE2;
    padding: 10px 0px; 

    img {
        margin-right: 20px;
        width: 30px;

    }

    .link-text {
        h1 {
            font-size: 1.125rem;
            line-height: 1.25rem;
            margin: 0;
            color: #1D1D1B;
        }

        p {
            font-size: 0.75rem;
            line-height: 0.875rem;
            margin-top: 5px;
            color: var(--black);
        }

    }
}

.dual-dropdown-menu:nth-last-of-type(2) .dropdown-link {
    border-bottom: none !important;
}

.dropdown-link:hover {
    img {
        filter: invert(63%) sepia(22%) saturate(7487%) hue-rotate(155deg) brightness(95%) contrast(101%);
    }

    .link-text {
        h1 {
            color: var(--color-blue);
        }

        p {
            color: var(--color-blue);
        }
    }

}

.phone-show {
    display: none;
}

.user-dropdown {
    button {
        padding: 0 !important;
        font-family: Stem-Regular;
        font-size: 1.125rem;
        line-height: 1.25rem;
        color: var(--other-black);
        background: none;
        border: none;
        outline: none;
    }

    .el-button:focus,
    .el-button:hover {
        background: unset;
        color: var(--other-black);
    }

}
.eye-control{
    position: relative;
    margin-right: 20px; 
    height: 100%; 
    .eye-button{
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .eye-button:hover{
        img{
            
            filter: invert(49%) sepia(95%) saturate(1359%) hue-rotate(157deg) brightness(97%) contrast(101%) !important;
        }
    }
    .active{
         img{
            
            filter: invert(49%) sepia(95%) saturate(1359%) hue-rotate(157deg) brightness(97%) contrast(101%) !important;
        }
    }
    .eye-controls{
        position: absolute;
        top: calc(100% + 18px); 
        transition: all 0.2s ease-in;
        overflow: hidden;
        left: 0;
        background: var(--white-bg);
        max-width: 320px;
        border-radius: 10px;
        padding:20px 20px 15px 20px;
        box-shadow: 0px 6.04511px 16.1203px -8.06015px rgba(0, 72, 153, 0.12), 0px 9.06767px 28.2105px rgba(0, 72, 153, 0.05), 0px 12.0902px 48.3609px 16.1203px rgba(0, 72, 153, 0.03);
        h1{
            text-align: center;
            font-family: Stem-Medium;
            font-size: 1.125rem;
            line-height: 1.25rem; 
            color: var(--other-black);
            margin-bottom: 10px;
        }
        .eye-control-buttons{
            display: flex;
            align-items: center;
            .eye-control-buttons-item{
                padding:10px;
            }
            .eye-img{
                width: 50px;
                max-height: 50px;
                cursor: pointer;
                img{
                    width: 100%;
                }
            }
        }
        .eye-bottom{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;
            padding: 10px;
            button{ 
                padding: 14px;
                color: var(--white-color);
                width: 150px;
                border-radius: 5px;
                border: none;
                background: #00B5EC;
                font-family: Stem-Medium;
                font-size: 1rem;
                line-height: 1.125rem;
                cursor: pointer;
            }
            p{
                font-family: Stem-Medium;
                font-size: 1rem;
                line-height: 1.125rem; 
                color: var(--grey-black);
                cursor: pointer;
            }
}
    }

}
.eye-phone{
    padding:0px 10px; 
    padding-left: 20px;
    h1{ 
            font-family: Stem-Medium;
            font-size: 0.875rem;
            line-height: 1rem; 
            color: var(--other-black);
            margin-bottom: 10px;
            padding: 0px 10px;
    }
    .eye-phone-items{
        display: flex;
        flex-wrap: wrap;
        .eye-phone-item{
            padding: 10px;
        }
        .eye-phone-button{
            width: 40px;
            cursor: pointer;
            img{
                width: 100%;
            }
        }
    }
    .eye-phone-bottom{
        display: flex;
        align-items: center; 
        padding: 10px 0px;
        padding-bottom: 20px; 
        button{
            padding: 10px 15px;
            margin-right: 20px;
            background: #00B5EC;
            border-radius: 5px;
            border: none;
            font-family: Stem-Medium;
            font-size: 0.875rem;
            line-height: 1rem; 
            color: var(--white-color);

        }
        p{
            font-family: Stem-Medium;
            font-size: 0.875rem;
            line-height: 1rem;  
            color:var(--grey-black)
        }
    }
}
.eye-phone-active{
    background: var(--white-bg);
     
}
.filter-blue{
    filter: invert(49%) sepia(95%) saturate(1359%) hue-rotate(157deg) brightness(97%) contrast(101%) !important;
}

@media screen and(max-width:1350px) {
    .nav {
        .logo-img {
            margin-right: 0px;
        }

        .nav-linkes {
            margin: 0 20px;

            .nav-link {
                margin: 0px 10px;
            }

        }

    }
}

@media screen and(max-width:1250px) {
    .nav {
        padding: 50px 20px;
    }
    .eye-control{
    position: relative;
    margin-right: 20px; 
    height: 100%;
    .eye-button{
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .eye-button:hover{
        img{
            
            filter: invert(49%) sepia(95%) saturate(1359%) hue-rotate(157deg) brightness(97%) contrast(101%) !important;
        }
    }
    .active{
         img{
            
            filter: invert(49%) sepia(95%) saturate(1359%) hue-rotate(157deg) brightness(97%) contrast(101%) !important;
        }
    }
    .eye-controls{ 
        right: 0; 
        left: auto;
        
        .eye-control-buttons{
             
            .eye-img{
                width: 40px;
                max-height: 40px;
                cursor: pointer;
                img{
                    width: 100%;
                }
            }
        }
        .eye-bottom{ 
            button{ 
                padding: 14px; 
                font-size: 0.875rem;
                line-height: 1rem; 
            }
            p{ 
                font-size: 0.875rem;
                line-height: 1rem;  
            }
}
    }

}
}

@media screen and(max-width:950px) {
    .desktop-show {
        display: none;
    }

    .phone-show {
        display: block !important;
    }

    .nav {
        padding: 0px;

        .nav-linkes {
            position: absolute;
            background: var(--bg-top);
            transition: all 0.3s ease-in;
            transform: translateY(-120%);
            height: 100vh;
            width: 100vw;
            overflow: auto;
            width: 100%;
            left: 0;
            top: 0;
            margin: 0; 
            display: block;
            padding: 20px 0px;
            padding-top: 70px;
            z-index: 100;
            border-radius: 0px 0px 10px 10px;
            box-shadow: 0px 6px 16px rgba(0, 72, 153, 0.12), 0px 9px 28px rgba(0, 72, 153, 0.05), 0px 12px 48px rgba(0, 72, 153, 0.03);
            align-items: flex-start;
            .nav-link {
                padding: 20px;
                width: 100%;
                margin: 0;
            }
             .nav-link-hover{
                display: none;
            }

            .nav-link-active {
                background: var(--white-bg);
                 .nav-link-hover{
                display: none;
            }
            }
        }

        .nav-linkes-active {
            transform: translateY(0%);
        }

        .user-controls {
            display: none;
        }

        .logo-img {
            position: relative;
            z-index: 101;

            img {
                width: 100px;
            }
        }
    }

    .nav-scroll {
        .nav {
            padding-top: 0px;
            padding-bottom: 0px;
            background: var(--white-bg);
            box-shadow: 0px 0px 6px rgba(0, 72, 153, 0.12);

            .nav-phone {
                background: var(--white-bg);
            }

        }
    }

    .phone-w-full {
        width: 100%;
    }

    .nav-phone {
        position: relative;
        z-index: 101;
        width: 100%;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: var(--bg-top);
    }

    .nav-active {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;

        .nav-phone {
            background: var(--white-bg);
        }
    }

    .burger {
        display: block;
        position: relative;
        z-index: 101;

        button {
            border: none;
            background: none;
            padding: 0;
            cursor: pointer;
            width: 24px;
            height: 24px;
            position: relative;
        }

        .burger-item1 {
            min-height: 3px;
            width: 24px;
            border-radius: 3px;
            background: #00B5EC;
            transition: all 0.3s ease-in;
        }

        .burger-item2 {
            min-height: 3px;
            width: 24px;
            border-radius: 3px;
            background: #00B5EC;
            margin: 5px 0px;
            transition: all 0.3s ease-in;
        }

        .burger-item3 {
            min-height: 3px;
            width: 24px;
            border-radius: 3px;
            background: #00B5EC;
            transition: all 0.3s ease-in;
        }

        .burger-active {
            .burger-item2 {
                transform: translateX(100%);
                opacity: 0;

            }

            .burger-item1 {
                transform: rotate(45deg);
                position: absolute;

                left: 0;
                top: 5px;
            }

            .burger-item3 {
                transform: rotate(-45deg);
                position: absolute;
                top: 5px;
            }
        }
    }

    .nav-info-phone {
        width: 100%;
        transition: all 0.3s ease-in;

        .nav-info-top {
            padding: 20px;
            display: flex;
            align-items: center;
            cursor: pointer;

            img {
                margin-left: 10px;
                transition: all 0.3s ease-in;
            }

            p {
                font-family: Stem-Regular;
                font-size: 1.125rem;
                line-height: 1.25rem;
            }
        }

        .nav-info-linkes {
            background: var(--white-bg);
            padding-left: 20px;

        }

        .nav-info-link {
            width: 100%;
            padding: 20px;
            display: flex;
            align-items: center;

            h1 {
                font-family: Stem-Regular;
                font-size: 1rem;
                line-height: 1.125rem;
                color: var(--other-black);
            }

            p {
                font-family: Stem-Regular;
                font-size: 0.75rem;
                line-height: 0.875rem;
                color: var(--white-black);
                margin-left: 10px;
            }
        }
    }

    .nav-info-phone-active {
        .nav-info-top {
            background: var(--white-bg);

            img {
                transform: rotate(180deg);
            }
        }
    }

    .nav-phone-langs {
        display: flex;
        padding: 20px;
        align-items: center;

        button {
            border: none;
            background: none;
            cursor: pointer;
            outline: none;
            font-family: Stem-Regular;
            font-size: 1rem;
            line-height: 1.125rem;
            color: var(--other-black);
            margin-right: 20px;
        }

        .active-lang {
            color: var(--color-blue);
        }
    }
}
</style>
