<template>
<div class="admin-nav-big">
    <div class="admin-nav">
        <div class="logo">
            <router-link tag="a" to="/">
                <img src="@/assets/img/white-logo.svg" alt="">
            </router-link>
        </div>
        <div class="user-controls">
            <div class="user-dropdown">
                <el-dropdown trigger="click" class="lang-dropdown">
                    <el-button class="dropdown-button el-dropdown-link">
                        <p> Admin<i class="el-icon-arrow-down el-icon--right"></i> </p>
                    </el-button>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item>
                                <p>Progile</p>
                            </el-dropdown-item>
                            <el-dropdown-item >
                                <p @click="logout" >Вийти</p>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
        </div>
    </div>
</div>
</template>
<script> 

export default {
    methods: {
         logout() {
            this.$store.commit('get_me', {
                payload: {}
            })
            localStorage.token = null
            localStorage.role = null
            this.$router.push('/admin/login')
        }
    },
}
</script>


<style lang="scss">
.admin-nav-big {
    height: 60px;
}

.admin-nav {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    color: var(--white-color);
    padding: 15px 20px;
    background: #44405C;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
        height: 100%;
        width: 200px;

        img {
            height: 100%;
        }
    }
    .user-dropdown {
    button {
        padding: 0 !important;
        font-family: Stem-Regular;
        font-size: 1.125rem;
        line-height: 1.25rem;
        color: var(--white-color);
        background: none;
        border: none;
        outline: none;
    }

    .el-button:focus,
    .el-button:hover {
        background: unset;
        color: var(--white-color);
    }

}
}

</style>
