import Vue from 'vue'
import Vuex from 'vuex' 
import axios from 'axios'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    me:{}, 
    Pages:[],
    StatPages:[],
    loader:false,
    Home:{
      key: "/", 
      data: {
        elements: {
          title_ua: '',
          title_en: '',
          name:'home'
        },
                    blog1: {
                        title_ua: '',
                        title_en: '',
                        description_ua: '',
                        description_en: '',
                    },
                    blog2: {
                        status: false,
                        title1_ua: '',
                        title1_en: '', 
                        title2_ua: '',
                        title2_en: '', 
                        title3_ua: '',
                        title3_en: '', 
                        title4_ua: '',
                        title4_en: '', 
                    },
                    blog3: {
                        title_ua: '',
                        title_en: ''
                    },
                    blog4: {
                        title_ua: '',
                        title_en: '',
                        description_ua: '',
                        description_en: '',
                        url:'#'

                    },
                    blog5: {
                      title_ua: '',
                      title_en: '',
                      description_ua: '',
                      description_en: '',
                      text_ua: '',
                      text_en: '',
                      button1_ua:'',
                      button1_en:'',
                      button1_url:'#',
                      button2_ua:'',
                      button2_en:'',
                      button2_url:'#'


                    },
                    blog6: {
                        title_ua: '',
                        title_en: '',
                        title1_ua: '',
                        title1_en: '',
                        url1:'',
                        title2_ua: '',
                        title2_en: '',
                        url2:'',
                        title3_ua: '',
                        title3_en: '',
                        url3:'',
                        title4_ua: '',
                        title4_en: '',
                        url4:'',
                        title5_ua: '',
                        title5_en: '',
                        url5:'',
                        button_ua:'',
                        button_en:'',
                        button_url:'#'
                    },
                    blog7: {
                        title_ua: '',
                        title_en: '',
                        description_ua: '',
                        description_en: '',
                        button_url:'#'
                    }

      },
                files: {
                    blog1Image1: '',
                    blog1Image2: '',
                    blog1Image3: '',
                    blog1Cloud:'', 
                    blog2Image1: '',
                    blog2Image2: '',
                    blog2Image3: '',
                    blog2Image4: '',
                    blog6Image1: '',
                    blog6Image2: '',
                    blog6Image3: '',
                    blog6Image4: '',
                    blog6Image5: '',
                    blog5Image1: '',
                    blog5Image2: '',
                    blog5Image3: '',
                    blog5Cloud: '',
                    blog7Image: '',
                    siteLogo_ua:'',
                    siteLogo_en:''
                }
    },
    Stock:{
      key: "/stock",
      data: {
         name_ua: '',
         name_en: '',
        title_ua: '',
        title_en: '',
        description_ua: '',
        description_en: '', 
      },
      files: {
        stockImage: '',
      }
    },
    Credit:{
      key: "/getcredit",
                data: {
                    name_ua: '',
                    name_en: '',
                    title_ua:'',
                    title_en:'',
                    description_ua:'',
                    description_en:'',
                    step2:{
                        title_ua:'',
                        title_en:'',
                        description_ua:'',
                        description_en:'',
                        button1_ua:'',
                        button1_en:'',
                        link1:'',
                        button2_ua:'',
                        button2_en:'',
                        link2:''
                    },
                    step4:{
                        title_ua:'',
                        title_en:'',
                        description_ua:'',
                        description_en:'',
                        phone:'',
                        phone_title_ua:'',
                        phone_title_en:'',
                        mail1:'',
                        mail2:'',
                        mail3:'',
                        adress_ua:'',
                        adress_en:'',
                    } 


                },
                files: {
                    step4Image: '',
                }
    },
    Contact:{
      key: "/contact",
                data: {
                    name_ua: '',
                    name_en: '',
                    title_ua: '',
                    title_en: '',
                    description_ua: '',
                    description_en: '', 
                    phone:'',
                    phone_title_ua:'',
                    phone_title_en:'',
                    mail1:'',
                    mail2:'',
                    mail3:'',
                    adress_ua:'',
                    adress_en:'',

                },
                files: {
                    contactImage: '',
                }
    },
    FAQ:{
      key: "/faq",
      data: {
          name_ua: '',
          name_en: '',
          link_dec_ua: '',
          link_dec_en: '',
          title_ua: '',
          title_en: '',
          description_ua: '',
          description_en: ''

      },
      files: {
          faqImage: '',
      }
    },
    Company:{
      key: "/procompany",
      data: {
          name_ua: '',
          name_en: '',
          link_dec_ua: '',
          link_dec_en: '',
          title_ua: '',
          title_en: '',
          description_ua: '',
          description_en: '',
          linkes: false,
          credit: false,
          contact: false,
          about_title_ua: '',
          about_title_en: '',
          about_text_ua: '',
          about_text_en: '',
          aboutBlock: [{
              id: 1,
              title_ua: '',
              title_en: ''
          }],
          finance_title_ua: '',
          finance_title_en: '',
          financeBlock: [{
              id: 1,
              title_ua: '',
              title_en: '',
          }],
          proect_title_ua: '',
          proect_title_en: '', 
          proect_description_ua:'',
          proect_description_en:'',
          proectBlock: [{
              id: 1,
              title_ua: '',
              title_en: ''
          }],
          partner_title_ua: '',
          partner_title_en: '',
          partner_descripton_ua: '',
          partner_descripton_en: '',
          partner_text_ua: '',
          partner_text_en: '',
          partnerBlock: [{
              id: 1,
              link: ''
          }]

      },
      files: {
          Image: '',
          aboutBlock1: '',
          financeBlock1: '',
          proectBlock1: '',
          partnerBlock1: ''
      }
    },
    Leasing:{
      name_ua: '',
      name_en: '',
      title_ua: '',
      title_en: '',
      description_ua: '',
      description_en: '',
      button1_title_ua:'',
      button1_title_en:'',
      button1_url:'',
      button2_title_ua:'',
      button2_title_en:'',
      linkes: false,
      credit: false,
      contact: false,
      links: [{
          id: 1,
          title_ua: '',
          title_en: '',
      }],
      technique_title1_ua:'',
      technique_title1_en:'',
      technique_title2_ua:'',
      technique_title2_en:'',
      technique_text_en:'',
      technique_text_ua:'',
      whyText_ua: '',
      whyText_en: '',
      types:[
        {
            title_ua:'',
            title_en:'',
            description1_ua:'',
            description1_en:'',
            description2_ua:'',
            description2_en:'',
            description3_ua:'',
            description3_en:'',
            url:'',
            button_ua:'',
            button_en:'',
        },
        {
            title_ua:'',
            title_en:'',
            description1_ua:'',
            description1_en:'',
            description2_ua:'',
            description2_en:'',
            description3_ua:'',
            description3_en:'',
            url:'',
            button_ua:'',
            button_en:'',
        },
        {
            title_ua:'',
            title_en:'',
            description1_ua:'',
            description1_en:'',
            description2_ua:'',
            description2_en:'',
            description3_ua:'',
            description3_en:'',
            url:'',
            button_ua:'',
            button_en:'',
        },
        {
            title_ua:'',
            title_en:'',
            description1_ua:'',
            description1_en:'',
            description2_ua:'',
            description2_en:'',
            description3_ua:'',
            description3_en:'',
            url:'',
            button_ua:'',
            button_en:'',
        },

    ],
      why: [{
          id: 1,
          title_ua: '',
          title_en: '',
          description_ua: '',
          description_en: ''
      }],
      about_title_ua: '',
      about_title_en: '',
      phone: '',
      phone_title_ua: '',
      phone_title_en: '',
      mails: [''],
      adress_ua: '',
      adress_en: '',

   
  files: {
    Image1: '',
    Image2: '',
    Image3: '',
    ImageClouds:'',
    whyImage1: '',
    whyImage2: '',
    whyImage3: '',
    linksImage1: ''
    }
    },
    Advantages:{
      key: "/advantages",
                data: {
                    name_ua: '',
                    name_en: '',
                    link_dec_ua: '',
                    link_dec_en: '',
                    title_ua: '',
                    title_en: '',
                    description_ua: '',
                    description_en: '',
                    linkes: false,
                    credit: false,
                    contact: false,
                    about: [{
                        id:1,
                        title1_ua: '',
                        title1_en: '',
                        title2_ua: '',
                        title2_en: '',
                        text_ua: '',
                        text_en: '', 
                    }]

                },
                files: {
                    Image: '',
                    aboutImage1: ''
                }
    },
    TemplateType:[
      {id:1, label:'Block '},
      {id:2, label:'Block with Image'},
      
    ],
    Calculator:{
      key: "/calculator",
      data: {
          max_sum:1000000,
          min_sum:1000,
          max_month:24,
          min_month:1,
          percent_month:10,
          percent_year:10

        },
        files: {
                 
        }
    },
    Footer:{
      key: "/footer",
      data: {
          button_ua:'',
          button_en:'',
          url:'',
          adress_ua:'',
          adress_en:'',
          phone:'',
          facebook:'',
          instagram:'',
          twitter:'',
          text_left_ua:'',
          text_left_en:'',
          text_right_ua:'',
          text_right_url:'',
          text_right_en:'',
          text_right_en_url:'',
          text_right_name_en:'',
          text_right_name_ua:''

      },
      files: {
          logo_ua: '',
          logo_en: '',
      }
    },
    Nav:{
      key: "/nav",
      data: {
          accaunt_ua:'',
          accaunt_en:'',
          credit_ua:'',
          credit_en:'',
          history_ua:'',
          history_en:'',
          message_ua:'',
          message_en:'',
          logout_ua:'',
          logout_en:'',
          set_name_ua:'', 
          set_name_en:'', 

          set_title_ua:'',
          set_title_en:'',
          set_submit_ua:'',
          set_submit_en:'',
          set_reset_ua:'',
          set_reset_en:'',
          information_ua:'',
          information_en:'',
          login_ua:'',
          login_en:'',


      },
      files: {
          logo_ua: '',
          logo_en: '',
      }
  },

  },
  getters:{
    role(state){
      return state.me.role_id
    }
  },
  mutations: {
    get_me(state, { payload }) {
      state.me = payload;
  },
  get_pages(state, {payload}){
    state.Pages=payload
  },
  get_statpages(state, {payload}){
    state.StatPages=payload
  },
  get_home(state, {payload}){
    state.Home=payload
  },
  set_loader(state, {payload}){
    state.loader=payload
  },
  set_stock(state, {payload}){
    state.Stock=payload
  },
  set_contact(state, {payload}){
    state.Contact=payload
  },
  set_credit(state, {payload}){
    state.Credit=payload
  },
  set_faq(state, {payload}){
    state.FAQ=payload
  },
  set_company(state, {payload}){
    state.Company=payload
  },
  set_leasing(state, {payload}){
    state.Leasing=payload
  },
  set_advantage(state, {payload}){
    state.Advantages=payload
  },
  set_calculator(state, {payload}){
    state.Calculator=payload
  },
  set_Footer(state, { payload }){
    state.Footer=payload
  },
  set_Nav(state, { payload }){
    state.Nav=payload
  },
  },
  actions: {
    get_me(context){ 
        axios.get('/user').then(res=>{ 
          context.commit('get_me', { payload:res.data}) 
          localStorage.role= res.data.role_id
        })
      },
    get_pages(context){ 
        axios.get('/page').then(res=>{  
          if(res.data.data.data.length>0){
            var pages=[]
             pages = res.data.data.data.filter(a=>{ 
              return a.status&&a.status==1
            })
            context.commit('get_pages', { payload:pages})  
          }
          
        })
      },
      get_statpages(context){
        context.commit('set_loader', {payload:true}) 
        axios.get('/statpage')
        .then(res => { 
            var data=res.data.data
            var home=data.filter(a=>{ return a.key==='/' })
            var stock=data.filter(a=>{ return a.key==='/stock' }) 
            var credit=data.filter(a=>{ return a.key==='/getcredit' }) 
            var contact=data.filter(a=>{ return a.key==='/contact' }) 
            var faq=data.filter(a=>{ return a.key==='/faq' }) 
            var company=data.filter(a=>{ return a.key==='/procompany' }) 
            var leasing=data.filter(a=>{ return a.key==='/leasing' }) 
            var advantages=data.filter(a=>{ return a.key==='/advantages' }) 
            var calculator=data.filter(a=>{ return a.key==='/calculator' }) 
            var footer=data.filter(a=>{ return a.key==='/footer' }) 
            var nav=data.filter(a=>{ return a.key==='/nav' }) 
            if(home[0]){
              context.commit('get_home', {payload:home[0]})
            }  
            if(stock[0]){ 
              context.commit('set_stock', {payload:stock[0]})
            }  
            if(credit[0]){ 
              context.commit('set_credit', {payload:credit[0]})
            }  
            if(contact[0]){ 
              context.commit('set_contact', {payload:contact[0]})
            }  
            if(faq[0]){ 
              context.commit('set_faq', {payload:faq[0]})
            } 
            if(company[0]){ 
              context.commit('set_company', {payload:company[0]})
            }   
            if(leasing[0]){ 
              context.commit('set_leasing', {payload:leasing[0]})
            }   
            if(advantages[0]){ 
              context.commit('set_advantage', {payload:advantages[0]})
            }  
            if(calculator[0]){  
              context.commit('set_calculator', {payload:calculator[0]})
            }  
            if(footer[0]){  
              context.commit('set_Footer', {payload:footer[0]})
            }  
            if(nav[0]){  
              context.commit('set_Nav', {payload:nav[0]})
            }  
            context.commit('set_loader', {payload:false})
            
        })
        .catch((e)=>{
          console.log(e)
          context.commit('set_loader', {payload:false})
        })
      }
  
    
   
  },
  modules: {
  }
})
