import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n' 
import ElementUI from 'element-ui';


import './assets/global.scss';


import locale from 'element-ui/lib/locale/lang/ru-RU'
import 'element-ui/lib/theme-chalk/index.css'; 
Vue.use(ElementUI, {locale})

import {SimpleSVG} from 'vue-simple-svg'
Vue.component('simple-svg', SimpleSVG)

Vue.config.productionTip = false 


import Scrollspy from 'vue2-scrollspy';
Vue.use(Scrollspy);

import NProgress from 'vue-nprogress'
Vue.use(NProgress)
const nprogress = new NProgress() 

import './plugins/axios/axios';

import AOS from 'aos'
import 'aos/dist/aos.css'
Vue.use(AOS)

 

import VueMask from 'v-mask'
Vue.use(VueMask);

import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)



// let editorOption={
//   debug: 'info',
//   modules: {
//       toolbar: [ 
//           'bold', 'italic', 'underline', 'strike', 'link', 
//           { 'list': 'ordered'}, { 'list': 'bullet' }, 
//           { 'color': [] }, { 'background': [] },
//           { 'align': [] }
//       ]
           
//   },
//   placeholder: 'text...',
//   readOnly: true,
//   theme: 'snow'
// }


 




// import { Vue2TinymceEditor } from "vue2-tinymce-editor";

 



// Vue.component('vue2-tinymce-editor', Vue2TinymceEditor) 

router.beforeEach(async (to, from, next)=>{  
   
 
  if(to.meta.requiresAuth&&(localStorage.role!=1 && localStorage.role!=2 && localStorage.role!=3 && localStorage.role!=4)){
    next('/login')
  }
  else{  
  var a=to.path.split('/') 
  if(a.length>1&&a[1]=='admin'){ a='admin' }
  else{ a='user' }
  if(a=='admin' && to.path!='/admin/login' ){ 
    if(localStorage.role==1){ next()}
    else{
      if(localStorage.role>1 &&  localStorage.role!=null ){ next('/404') }
      else{ next('/admin/login') }
    }
  }
  else{  next() }
  }
})





import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)


new Vue({
  nprogress,
  i18n,
  created() {
    AOS.init({
      offset: 0,
      once: false, 
      debounceDelay: 100,
      duration:800,
      easing:'ease-in'
    });
  },
  render: h => h(App),
  store,
  router
 
}).$mount('#app')
